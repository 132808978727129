import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import NavMenu from './nav-menu';
import HamburgerNavMenu from './hamburger-nav-menu';

const query = graphql`
  query menuLinksQuery {
    allMenuLinksJson {
      edges {
        node {
          name
          link
        }
      }
    }
  }
`;

const Header = () => {
  const data = useStaticQuery(query);
  const menuLinks = data.allMenuLinksJson.edges.map(edge => edge.node);

  return (
    <header className="header">
      <div className="logo">
        {'Gustavo '}
        <span className="colored">{'del Pino'}</span>
      </div>
      <NavMenu menuLinks={menuLinks} />
      <HamburgerNavMenu menuLinks={menuLinks} />
    </header>
  );
};

export default Header;
