import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Footer from '../footer/footer';

const PageLayoutBody = ({ children, className }) => (
  <section className="page-layout-body">
    <div className={classNames('body-section', className)}>{children}</div>
    <Footer />
  </section>
);

PageLayoutBody.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

PageLayoutBody.defaultProps = {
  className: '',
};

export default PageLayoutBody;
