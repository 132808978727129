import React from 'react';
import { IoLogoTwitter, IoLogoFacebook, IoLogoYoutube } from 'react-icons/io';
import { FaSoundcloud } from 'react-icons/fa';
const SocialMediaIcons = () => (
  <section className="social-media-wrapper">
    <FaSoundcloud className="social-media-icon soundcloud" />
    <IoLogoTwitter className="social-media-icon twitter" />
    <IoLogoYoutube className="social-media-icon youtube" />
  </section>
);

export default SocialMediaIcons;
