import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const PageLayout = ({ children, className }) => (
  <article
    className={classNames('page-layout page-background-base', className)}
  >
    {children}
  </article>
);

PageLayout.default = {
  className: '',
};

PageLayout.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

export default PageLayout;
