import React from 'react';
import { Link } from 'gatsby';

// Credit to Julien Lejeune: https://codepen.io/jlnljn/pen/LkXoBb
const HamburgerNavMenu = ({ menuLinks }) => (
  <div className="hamburger-menu">
    <input
      id="burger-button-input"
      className="burger-button-input"
      type="checkbox"
    />
    <label htmlFor="burger-button-input" className="burger-button">
      <span />
      <span />
      <span />
    </label>
    <nav className="hamburger-nav">
      <ul className="hamburger-nav-list">
        {menuLinks.map((menu, index) => (
          <li className="hamburger-nav-list-item" key={menu.link}>
            <Link
              className="navmenu-link"
              activeClassName="navmenu-link-active"
              to={menu.link}
              key={index}
            >
              {menu.name}
            </Link>
          </li>
        ))}
      </ul>
    </nav>
  </div>
);

export default HamburgerNavMenu;
