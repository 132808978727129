import React from 'react';
import {
  PageLayout,
  PageLayoutHeader,
  PageLayoutBody,
} from '../components/page-layout';
import SEO from '../components/seo';

const NotFoundPage = () => (
  <PageLayout className="biography-background">
    <SEO title="404: Not found" />
    <PageLayoutHeader />
    <PageLayoutBody>
      <h1>NOT FOUND</h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </PageLayoutBody>
  </PageLayout>
);

export default NotFoundPage;
