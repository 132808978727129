import React from 'react';
import { Link } from 'gatsby';

const NavMenu = ({ menuLinks }) => (
  <nav className="navmenu">
    {menuLinks.map((menu, index) => (
      <Link
        className="navmenu-link"
        activeClassName="navmenu-link-active"
        to={menu.link}
        key={index}
      >
        {menu.name}
      </Link>
    ))}
  </nav>
);

export default NavMenu;
