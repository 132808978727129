import React from 'react';
import Header from '../header/header';

const PageLayoutHeader = () => (
  <section className="page-layout-header">
    <Header />
  </section>
);

export default PageLayoutHeader;
