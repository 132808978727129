import React from 'react';
import SocialMediaIcons from './social-media-icons';

const Footer = () => (
  <section className="footer-wrapper">
    <SocialMediaIcons />
    <span className="copyright-disclaimer">
      Copyright &copy; 2019 All rights reserved
    </span>
  </section>
);

export default Footer;
